import JsonServerProvider from './JsonServerProvider';
const dataProvider = JsonServerProvider(process.env.REACT_APP_APIURL);
const imageFields = ['image', 'imageWide'];
const fileFields = ['file'];
const myDataProvider = {
	...dataProvider,
	update: async (resource, params) => {
		let hasImages = false;
		let hasFiles = false;
		let images = [];
		let files = [];
		console.log(params.data);
		imageFields.forEach((imageField) => {
			if (Object.keys(params.data).indexOf(imageField) !== -1 && params.data[imageField] !== null) {
				if (Array.isArray(params.data[imageField])) {
					for (const item of params.data[imageField]) {
						if (item.hasOwnProperty('rawFile')) {
							if (typeof item === 'object') {
								hasImages = true;
								item.field = imageField;
								item.newName = Math.random().toString(36).substr(2, 30);
								images.push(item);
							}
						}
					}
				} else {
					if (params.data[imageField].hasOwnProperty('rawFile')) {
						if (typeof params.data[imageField] === 'object') {
							hasImages = true;
							params.data[imageField].field = imageField;
							params.data[imageField].newName = Math.random().toString(36).substr(2, 30);
							images.push(params.data[imageField]);
						}
					}
				}
			}
		});
		fileFields.forEach((fileField) => {
			if (Object.keys(params.data).indexOf(fileField) !== -1 && params.data[fileField] !== null) {
				if (Array.isArray(params.data[fileField])) {
					params.data[fileField].forEach((element, i) => {
						if (element.hasOwnProperty('rawFile')) {
							if (typeof element === 'object') {
								hasFiles = true;
								params.data[fileField][i].field = fileField;
								params.data[fileField][i].newName = Math.random().toString(36).substr(2, 30);
								files.push(params.data[fileField][i]);
							}
						}
					});
				}
			}
		});
		console.log('dataProvider');
		console.log('hasImages', hasImages);
		console.log('hasFiles', hasFiles);
		if (hasImages || hasFiles) {
			// let promiseConvert = []
			let convertedImages = [];
			for (const image of images) {
				if (image.rawFile instanceof File) {
					convertedImages.push(await convertFileToBase64(image));
				}
			}
			let convertedFiles = [];
			for (const file of files) {
				if (file.rawFile instanceof File) {
					convertedFiles.push(await convertFileToBase64(file));
				}
			}
			let data = { ...params.data };
			if (hasImages) data.images = [...convertedImages];
			if (hasFiles) data.files = [...convertedFiles];

			return dataProvider.update(resource, {
				...params,
				data,
			});
			// images.forEach(image => {
			// 	if(image.rawFile instanceof File){
			// 		promiseConvert.push(convertFileToBase64(image))
			// 	}
			// });
			// return Promise.all(promiseConvert).then((convertedImages)=>{
			// 	dataProvider.update(resource, {
			// 		...params,
			// 		data: {
			// 			...params.data,
			// 			images: [
			// 				...convertedImages
			// 			]
			// 		}
			// 	})
			// })
		} else {
			return dataProvider.update(resource, params);
		}
	},
};

const convertFileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		console.log('convertFileToBase64');
		console.log(file);
		const reader = new FileReader();

		reader.onload = () => {
			file.base64 = reader.result;
			resolve(file);
		};
		reader.onerror = reject;

		reader.readAsDataURL(file.rawFile);
	});

export default myDataProvider;
