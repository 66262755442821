import polyglotI18nProvider from 'ra-i18n-polyglot';

import ru from '../assets/locales/ru/translation.json';
import en from '../assets/locales/en/translation.json';
import fr from '../assets/locales/fr/translation.json';
import es from '../assets/locales/es/translation.json';

const langs = [
	{
		id: 'en',
		key: 'en',
		title: 'English',
		name: 'English',
	},
	{
		id: 'es',
		key: 'es',
		title: 'Espaniol',
		name: 'Espaniol',
	},
	{
		id: 'fr',
		key: 'fr',
		title: 'France',
		name: 'France',
	},
	{
		id: 'ru',
		key: 'ru',
		title: 'Русский',
		name: 'Русский',
	},
];

function getLang() {
	const localStorageLang = localStorage.getItem('localeContent');
	if (localStorageLang) return localStorageLang;
	if (navigator.languages !== undefined) return navigator.languages[0].split('-')[0];
	return navigator.language.split('-')[0];
}
function setLang(lang) {
	localStorage.setItem('localeContent', lang);
}
function combineLangObjects(lang) {
	let messages;
	switch (lang) {
		case 'en':
			messages = en;
			break;
		case 'ru':
			messages = ru;
			break;
		case 'es':
			messages = es;
			break;
		case 'fr':
			messages = fr;
			break;
		default:
			messages = ru;
			break;
	}
	return messages;
}
function geti18nProvider() {
	return polyglotI18nProvider(() => {
		return combineLangObjects(getLang());
	}, 'en');
	// return combineLangObjects(getLang())
}
export { langs, getLang, setLang, geti18nProvider };
