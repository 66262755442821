import React, { useRef, useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import ReCAPTCHA from 'react-google-recaptcha';
import classes from './css/Login.module.css';
import { Lock } from '@mui/icons-material';
import { TextField, Button } from '@mui/material';

const reCAPTCHA = process.env.REACT_APP_CAPTCHA;

const CustomLogin = (props) => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [usernameError, setUsernameError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const login = useLogin();
	const notify = useNotify();
	const recaptchaRef = useRef();
	const [loading, setLoading] = useState(false);

	const handleCaptchaChange = async (captcha) => {
		try {
			await login({ username, password, captcha });
		} catch (e) {
			notify(e.message);
		}
		setLoading(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!username.trim()) {
			setUsernameError(true);
			return;
		}
		if (!password.trim()) {
			setPasswordError(true);
			return;
		}

		setLoading(true);
		setUsernameError(false);
		setPasswordError(false);

		await recaptchaRef.current.reset();
		await recaptchaRef.current.executeAsync();
	};

	return (
		<div className={classes.login_page_custom}>
			<form
				onSubmit={handleSubmit}
				className={classes.authorization_form}
				style={{ backgroundImage: 'unset' }}
				{...props}>
				<Lock className={classes.lockIcon} />
				<div className={classes.input_container}>
					<TextField
						label="Имя пользователя"
						name="username"
						type="text"
						value={username}
						onChange={(e) => {
							setUsername(e.target.value);
							setUsernameError(false);
						}}
						error={usernameError}
						helperText={usernameError && 'Обязательно для заполнения'}
						size="small"
						sx={{
							width: '100%',
							marginBottom: 1,
						}}
					/>
					<TextField
						label="Пароль"
						name="password"
						type="password"
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
							setPasswordError(false);
						}}
						error={passwordError}
						helperText={passwordError && 'Поле не может быть пустым'}
						size="small"
						sx={{
							width: '100%',
							marginBottom: 1,
						}}
					/>
				</div>
				<Button
					variant="contained"
					color="primary"
					type="submit"
					disabled={loading}
					sx={{
						width: '100%',
					}}>
					Войти
				</Button>
			</form>
			<Notification />
			<ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={reCAPTCHA} onChange={handleCaptchaChange} />
		</div>
	);
};

export default CustomLogin;
