import * as React from 'react';
import { AppBar, UserMenu } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import classes from '../css/AppBar.module.css';
import LogoTymio from '../../assets/img/tymio-logo.png';

const MyUserMenu = (props) => <UserMenu {...props} />;

const CustomAppBar = (props) => {
	const navigate = useNavigate();

	return (
		<AppBar {...props} userMenu={<MyUserMenu />}>
			<img onClick={() => navigate('/')} src={LogoTymio} alt="" className={classes.logo} />
			<span className={classes.spacer} />
		</AppBar>
	);
};

export default CustomAppBar;
