import * as React from 'react';
import {
	TopToolbar,
	CreateButton,
	ExportButton,
	FilterButton,
	Button,
	ListButton,
	Pagination,
	useTranslate,
} from 'react-admin';
import classes from '../model/css/Components.module.css';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import ImportButton from '../components/ImportButton';

export const DefaultPagination = (props) => <Pagination rowsPerPageOptions={[100, 250]} {...props} />;
export const DefaultEditCreateActions = ({ basePath, data, resource }) => {
	let navigate = useNavigate();
	const t = useTranslate();
	return (
		<TopToolbar>
			<ListButton
				basePath={basePath}
				label={t('ra.action.back')}
				icon={<ChevronLeft />}
				onClick={() => {
					navigate(-1);
				}}
			/>
		</TopToolbar>
	);
};
export const DefaultShowActions = ({ basePath, data, resource }) => {
	let navigate = useNavigate();
	const t = useTranslate();
	return (
		<TopToolbar>
			<ListButton
				basePath={basePath}
				label={t('ra.action.back')}
				icon={<ChevronLeft />}
				onClick={() => {
					navigate(-1);
				}}
			/>
		</TopToolbar>
	);
};

export const DefaultListActions = (props) => {
	// const langs = [];
	const langs = ['ru', 'en', 'es', 'fr'];
	const defaultLang = 'ru';
	const createButton = props.create !== undefined ? props.create : true;
	const filterButton = props.filter !== undefined ? props.filter : false;
	const exportButton = props.export !== undefined ? props.export : true;
	const importButton = props.import !== undefined ? props.import : false;
	const resource = props.resource !== undefined ? props.resource : '';

	if (localStorage.getItem('localeContent') === null) localStorage.setItem('localeContent', defaultLang);

	const changeLang = (lang) => {
		localStorage.setItem('localeContent', lang);
		window.location.reload();
	};

	return (
		<TopToolbar className={classes.display__block}>
			<div className={classes.flex__between}>
				<div>
					{langs.map((lang, index) => (
						<Button
							key={index}
							className={`${classes.label_no_padding} ${
								localStorage.getItem('localeContent') === lang ? classes.active__btn : ''
							}`}
							onClick={() => changeLang(lang)}
							label={lang}></Button>
					))}
				</div>
				<div>
					{importButton && resource ? <ImportButton resource={resource} /> : ''}
					{filterButton ? <FilterButton /> : ''}
					{createButton ? <CreateButton /> : ''}
					{exportButton ? <ExportButton maxResults={props.total} /> : ''}
				</div>
			</div>
		</TopToolbar>
	);
};
