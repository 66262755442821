import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import Dashboard from './providers/Dashboard';
import AuthProvider from './providers/AuthProvider';
import DataProvider from './providers/DataProvider';
import Layout from './providers/Layout';
import Login from './providers/Login';
import Routes from './providers/Routes';
import { geti18nProvider } from './providers/Translate';

import { ArticleList, ArticleEdit, ArticleCreate } from './model/Article';
import { CategoryList, CategoryEdit, CategoryCreate } from './model/Category';

const i18nProvider = geti18nProvider();
let theme;
async function importModule(path) {
	try {
		theme = await import(`${path}`);
	} catch (error) {
		console.error('import failed');
	}
}
importModule('./lib/themeTymio');

const App = () => {
	return (
		<Admin
			title="Admin Panel"
			loginPage={Login}
			dashboard={Dashboard}
			layout={Layout}
			authProvider={AuthProvider}
			dataProvider={DataProvider}
			i18nProvider={i18nProvider}
			customRoutes={Routes}
			theme={theme}>
			<Resource name="article_crud" list={ArticleList} edit={ArticleEdit} create={ArticleCreate} />
			<Resource name="category_crud" list={CategoryList} edit={CategoryEdit} create={CategoryCreate} />
		</Admin>
	);
};
export default App;
