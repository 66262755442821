import * as React from 'react';
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	Edit,
	SimpleForm,
	Toolbar,
	SaveButton,
	TextInput,
	NumberInput,
	ReferenceInput,
	SelectInput,
	SelectArrayInput,
	Create,
	BooleanInput,
} from 'react-admin';
import { DefaultEditCreateActions, DefaultPagination, DefaultListActions } from '../lib/defaultConfig';
import { langs } from '../providers/Translate';

export const tableFields = ['id', 'title'];
const editFields = ['id', 'managerId'];

const CustomDatagrid = (props) => {
	return (
		<Datagrid {...props}>
			{tableFields.map((source) => (
				<TextField source={source} key={source} />
			))}
			<ReferenceField label="Parent category" source="parentId" reference="category_crud">
				<TextField source="title" />
			</ReferenceField>
		</Datagrid>
	);
};

export const CategoryList = (props) => {
	return (
		<List {...props} pagination={<DefaultPagination />} actions={<DefaultListActions />} exporter={false}>
			<CustomDatagrid rowClick="edit" />
		</List>
	);
};

const SaveEditToolbar = (props) => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

const simpleForm = (
	<SimpleForm toolbar={<SaveEditToolbar />} redirect="list">
		{editFields.map((source) => (
			<TextField source={source} key={source} />
		))}
		<TextInput source="title" label="Title" />
		<TextInput source="desc" label="Description" />
		<BooleanInput source="visible" lable="Visible" />
		<SelectArrayInput choices={langs} label={'Visible lang'} source='visibleLang' defaultValue={langs.map(lang => lang.id)} />
		<ReferenceInput source="parentId" reference="category_crud" allowEmpty="false" defaultValue={0} fullWidth>
			<SelectInput optionText="title" />
		</ReferenceInput>
		<NumberInput source="orderId" label="Order id" />
	</SimpleForm>
);

export const CategoryEdit = (props) => (
	<Edit {...props} actions={<DefaultEditCreateActions />} mutationMode="optimistic">
		{simpleForm}
	</Edit>
);

export const CategoryCreate = (props) => (
	<Create {...props} actions={<DefaultEditCreateActions />} mutationMode="optimistic">
		{simpleForm}
	</Create>
);
