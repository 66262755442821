import * as React from 'react';
import { Layout } from 'react-admin';

import AppBar from './components/AppBar';
import { AdminMenu } from './components/Menu';

const CustomLayout = (props) => {
	return <Layout {...props} menu={AdminMenu} appBar={AppBar} />;
};

export default CustomLayout;
