import * as React from 'react';
import ImagePicker from './components/ImagePicker';
import {
	useNotify,
	List,
	Datagrid,
	TextField,
	Edit,
	SimpleForm,
	Toolbar,
	SaveButton,
	TextInput,
	NumberInput,
	ReferenceInput,
	SelectInput,
	ImageInput,
	ImageField,
	Create,
	BooleanInput,
	Button,
	FormDataConsumer,
	SelectArrayInput,
	ReferenceField,
} from 'react-admin';
import { DefaultEditCreateActions, DefaultPagination, DefaultListActions } from '../lib/defaultConfig';
import classes from './css/Components.module.css';
import { POST } from '../api/fetch-api';
import { langs } from '../providers/Translate';

export const tableFields = ['id', 'title'];
const editFields = ['id'];


const CustomDatagrid = (props) => {
	return (
		<Datagrid {...props}>
			{tableFields.map((source) => (
				<TextField source={source} key={source} />
			))}
			<ReferenceField label="Category" source="categoryId" reference="category_crud">
				<TextField source="title" />
			</ReferenceField>
		</Datagrid>
	);
};

export const ArticleList = (props) => {
	return (
		<List {...props} pagination={<DefaultPagination />} actions={<DefaultListActions />} exporter={false}>
			<CustomDatagrid rowClick="edit" />
		</List>
	);
};

const onTranslate = async (formData, lang, notify) => {
	notify(`Job for translation to ${lang} sended`, { type: 'info', multiLine: true });
	await POST(`${process.env.REACT_APP_APIURL}/set_translate`, {
		id: formData.id,
		message: formData.content,
		l: lang
	});
	notify('Done', { type: 'success', multiLine: true });
};

const SaveEditToolbar = (props) => {
	const notify = useNotify();
	return (
		<Toolbar {...props}>
			<SaveButton />
			<FormDataConsumer>
				{({ formData, ...rest }) => <Button onClick={() => onTranslate(formData, 'en', notify)}>EN</Button>}

			</FormDataConsumer>
			<FormDataConsumer>
				{({ formData, ...rest }) => <Button onClick={() => onTranslate(formData, 'es', notify)}>ES</Button>}
			</FormDataConsumer>
			<FormDataConsumer>
				{({ formData, ...rest }) => <Button onClick={() => onTranslate(formData, 'fr', notify)}>FR</Button>}
			</FormDataConsumer>
		</Toolbar>
	);
};

const simpleForm = (
	<SimpleForm toolbar={<SaveEditToolbar />} redirect="list">
		{editFields.map((source) => (
			<TextField source={source} key={source} />
		))}

		<TextInput source="title" label="Title" />
		<TextInput source="desc" label="Description" />
		<BooleanInput source="visible" lable="Visible" />
		<SelectArrayInput choices={langs} label={'Visible lang'} source='visibleLang' defaultValue={langs.map(lang => lang.id)} />

		<ImagePicker source="image" fullWidth />
		<TextInput multiline className={classes.full_width} source="content" label="Content" />

		<NumberInput source="orderId" label="Order id" />
		<ReferenceInput source="categoryId" reference="category_crud" allowEmpty="false" defaultValue={0} fullWidth>
			<SelectInput optionText="title" />
		</ReferenceInput>
		<ImageInput source="image" multiple>
			<ImageField source="src" title="title" />
		</ImageInput>
	</SimpleForm>
);

export const ArticleEdit = (props) => (
	<Edit {...props} actions={<DefaultEditCreateActions />} mutationMode="optimistic">
		{simpleForm}
	</Edit>
);

export const ArticleCreate = (props) => (
	<Create {...props} actions={<DefaultEditCreateActions />} mutationMode="optimistic">
		{simpleForm}
	</Create>
);
