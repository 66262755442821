import Cookies from 'universal-cookie';
const cookies = new Cookies();

const convertToQueryParams = (params = {}) => {
	let queryString = '';
	Object.keys(params).forEach((key, index) => {
		if (index === 0) queryString += `?${key}=${params[key]}`;
		else queryString += `&${key}=${params[key]}`;
	});
	return queryString;
};

export const GET = (url = '', params = {}) => {
	const token = cookies.get('token');
	return new Promise((resolve, reject) => {
		fetch(url + convertToQueryParams(params), {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'A-Auth-Token': token,
				'Content-Type': 'application/json',
			},
		})
			.then(async (response) => {
				const json = await response.json();
				if (json && json.error && json.error === 'Access denied') {
					return Promise.reject(new Error(json.error));
				}
				resolve(json);
			})
			.catch((err) => {
				reject(new Error(err.message));
			});
	});
};

export const POST = async (url = '', data = {}) => {
	const token = cookies.get('token');
	return new Promise((resolve, reject) => {
		fetch(url, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'A-Auth-Token': token,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then(async (response) => {
				const json = await response.json();
				if (json && json.error && json.error === 'Access denied') {
					return Promise.reject(new Error(json.error));
				}
				resolve(json);
			})
			.catch((err) => {
				reject(new Error(err.message));
			});
	});
};
