import * as React from 'react';
import { Link } from 'react-router-dom';

import HelpCenterIcon from '@mui/icons-material/HelpCenter';

import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import '../css/Sidebar.module.css';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const AdminMenu = () => {
	let role = cookies.get('role');
	role = Array.isArray(role) ? role : [];

	const checkRole = (roles) => {
		if (roles === undefined) roles = ['admin'];
		let has = false;
		for (const r of roles) {
			if (~role.indexOf(r)) {
				has = true;
				break;
			}
		}
		return has;
	};

	const res = (
		<Sidebar>
			<Menu iconShape="square">
				{checkRole() ? (
					<SubMenu title="Helpcenter" label="Helpcenter" icon={<HelpCenterIcon />}>
						<Link to="/article_crud" ><MenuItem>Articles</MenuItem></Link>
						<Link to="/category_crud" ><MenuItem>Category</MenuItem></Link>
					</SubMenu>
				) : (
					''
				)}
			</Menu>
		</Sidebar>
	);
	return res;
};
