import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

export default function ImportButton({ resource, ...props }) {
	const navigate = useNavigate();

	const routeChange = (resource) => {
		const path = `${resource}_import`;
		navigate(path);
	};

	return (
		<Button onClick={() => routeChange(resource)} {...props}>
			Import
		</Button>
	);
}
