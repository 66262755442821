import React from 'react';
import classes from '../css/Components.module.css';
import { FormLabel } from '@mui/material';
import { useInput, useNotify } from 'react-admin';

const imageServerUrl = process.env.REACT_APP_IMAGEURL || '';

const ImagePicker = (props) => {
	const notify = useNotify();
	const { source, ...rest } = props;

	const {
		field: { value, onChange, name },
	} = useInput({ source, ...rest });

	const handleClipboard = (imgUrl) => {
		navigator.clipboard.writeText(imgUrl);
		notify('Copied to clipboard', { type: 'success' });
	};

	const handleDelete = (image) => {
		const copy = JSON.parse(JSON.stringify(value));
		const index = copy.indexOf(image);
		if (index > -1) {
			copy.splice(index, 1);
		}
		onChange(copy);
	};

	return (
		<>
			{value && (
				<>
					{name ? <FormLabel>{name}</FormLabel> : <></>}
					<div className={classes.article__images}>
						{value.map((item) => (
							<div className={classes.article__image}>
								<img
									src={`${imageServerUrl}/${item}`}
									alt="img"
									width={150}
									onClick={() => handleClipboard(`${imageServerUrl}/${item}`)}
								/>
								<div className={classes.cross} onClick={() => handleDelete(item)}>
									X
								</div>
							</div>
						))}
					</div>
				</>
			)}
		</>
	);
};

export default ImagePicker;
